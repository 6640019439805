import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {
    Form,
    Field,
    defineRule,
    configure,
    extend
} from "vee-validate";
import * as rules from "@vee-validate/rules";
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import {
    localize
} from "@vee-validate/i18n";
import { VueReCaptcha } from "vue-recaptcha-v3";
import twitter from 'vue-twitter'
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
configure({
    generateMessage: localize("es", {
        messages: {
            required: "Este campo es obligatorio",
            email: 'Introduce un email válido',
            digits: 'Introduce un teléfono válido',
            min: 'Debe tener mínimo 8 carácteres',
            confirmed: 'No coincide con la contraseña'
        },
    }),
});

Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
});

//Configure Axios
axios.defaults.baseURL = process.env.VUE_APP_BACK;

let app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(Field, Form);
app.use(LoadingPlugin);
app.use(VCalendar, {});
app.use(OpenLayersMap);
app.use(VueReCaptcha, { siteKey: "6LfuytMiAAAAAPtOax0ju-F88aSDVEHRNZOfCWUC" });
app.use(twitter);

app.config.globalProperties.cutText = function(text, limit){
    if (text && text.length > limit) {
        text = text.substring(0, limit) + "...";
    }
    return text
}
app.mount('#app');